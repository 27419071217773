import { useState } from 'react'
import { NavLink } from 'react-router-dom'

import { Container } from './styles'

import { ReactComponent as Calculator } from '../../assets/calculator.svg'
import { ReactComponent as Marketplace } from '../../assets/marketplace.svg'
import { ReactComponent as Instagram } from '../../assets/instagram.svg'
import { ReactComponent as WhatsappIcon } from '../../assets/whatsapp-icon.svg'

import menuImg from '../../assets/menu.svg'

export function Sidebar() {
  const [menuOpen, setMenuOpen] = useState(false)

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  return (
    <Container isMenuOpen={menuOpen}>
      <button type='button' onClick={handleToggleMenu}>
        <img src={menuImg} alt='Abrir e fechar o menu' />
      </button>
      <nav>
        <ul>
          <li>
            <NavLink to='/'>
              <Calculator />
              <span>Calculadora</span>
            </NavLink>
          </li>
          <li className="marketplace-icon-li">
            <a href="https://www.aromaacessorios.com.br" target="_blank" rel="noopener noreferrer">
              <Marketplace className="marketplace-icon" />
              <span>Loja Virtual</span>
            </a>
          </li>
          <li>
            <a href="https://www.aromaacessorios.com.br" target="_blank" rel="noopener noreferrer">
              <Instagram />
              <span>Instagram</span>
            </a>
          </li>
          <li>
            <a href="https://wa.me/+5511987198919" target="_blank" rel="noopener noreferrer">
              <WhatsappIcon />
              <span>Whatsapp</span>
            </a>
          </li>
        </ul>
      </nav>
    </Container>
  )
}