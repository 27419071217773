import React, { useState } from 'react';
import { Head } from '../../../components/Head';
import { LogoContainer, FormContainer, InputField, Button, InfoButton, BackButton, CalculationResult, Title } from './style';
import logoImg from '../../../assets/logo.webp';
import dropImg from '../../../assets/drop.png'; // Importa a imagem

export default function Drinks() {
    const [showCalculationResult, setShowCalculationResult] = useState(false); // Exibir o resultado do cálculo
    const [showCalculationInfo, setShowCalculationInfo] = useState(false); // Exibir explicação "Sobre o cálculo"
    const [gotas, setGotas] = useState<number | null>(null); // Número de gotas calculadas
    const [concentracao, setConcentracao] = useState<number | null>(null); // Concentração usada
    const [ml, setMl] = useState<number | null>(null); // Quantidade de óleo vegetal

    const handleCalculate = () => {
        const mlValue = 10; // Exemplo fixo (você pode torná-lo dinâmico)
        const concentrationValue = 10; // Exemplo fixo (você pode torná-lo dinâmico)
        const gotasCalculadas = (mlValue * concentrationValue * 0.25).toFixed(1);

        setMl(mlValue);
        setConcentracao(concentrationValue);
        setGotas(Number(gotasCalculadas));
        setShowCalculationResult(true); // Exibe o resultado do cálculo
        setShowCalculationInfo(false); // Fecha a explicação
    };

    const handleBack = () => {
        setShowCalculationResult(false); // Volta à calculadora
        setShowCalculationInfo(false); // Fecha a explicação
    };

    const handleToggleInfo = () => {
        setShowCalculationInfo(true); // Exibe a explicação "Sobre o cálculo"
        setShowCalculationResult(false); // Fecha o resultado do cálculo
    };

    return (
        <>
            <Head title='Calculadora de Diluição' />
            <FormContainer>
                {/* Logo e título fixos no topo do quadro branco */}
                <LogoContainer>
                    <img src={logoImg} alt="Logo" />
                </LogoContainer>
                <Title>Calculadora para diluição de óleos essenciais</Title>

                {showCalculationResult ? (
                    <CalculationResult>
                        <div
                            style={{
                                width: '237px',
                                height: '345px',
                                backgroundImage: `url(${dropImg})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                margin: '0 auto',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <p style={{ color: '#fff', fontSize: '1.2rem', fontWeight: 'bold', margin: 0 }}>
                                {gotas} gota(s)
                            </p>
                        </div>
                        <p>
                            Para uma concentração de <strong>{concentracao}%</strong> em <strong>{ml}ml</strong> de óleo vegetal,
                            você deve diluir <strong>{gotas} gota(s)</strong> (valor não arredondado) do óleo essencial em seu frasco!
                        </p>
                        <BackButton onClick={handleBack}>Voltar à calculadora</BackButton>
                    </CalculationResult>
                ) : showCalculationInfo ? (
                    <CalculationResult>
                        <h3>Aprenda como o cálculo é feito!</h3>
                        <p><strong>Número de Gotas</strong> = (Qtd. de OV (ml) x % desejada do OE * 0,25)</p>
                        <ul>
                            <li><strong>OV</strong>: Óleo Vegetal</li>
                            <li><strong>OE</strong>: Óleo Essencial</li>
                            <li><strong>0,25</strong>: quer dizer que estamos considerando que 25 gotas de óleo essencial equivalem a 1mL de solução.</li>
                        </ul>

                        <p><strong>Curiosidade:</strong> você sabia que gota não é unidade de medida? Muitas pessoas se enganam! Isso acontece porque existem vários tamanhos de gotas. &quot;Gota&quot; é um nome genérico sem medida exata. As gotas de chuva, por exemplo, são de tamanhos diferentes a depender do tempo do dia, mas são sempre gotas! A unidade de medida de líquidos é Litros (L) e suas frações como mL.</p>

                        <p><strong>Detalhe:</strong> a calculadora arredonda a quantidade de gotas para mais ou para menos. Por exemplo, se o valor final de gotas for até 2,4, esse valor é arredondado para 2. Caso o valor final seja 2,5+, o arredondamento será para 3. Mas, também, o valor original (sem arredondamentos) é mostrado na descrição detalhada do cálculo!</p>

                        <p><strong>Aroma Acessórios:</strong> espalhando aos quatro ventos a mensagem das plantas!</p>
                        <p><strong>Ilka</strong><br />Cargo Texto</p>
                        <BackButton onClick={handleBack}>Voltar à calculadora</BackButton>
                    </CalculationResult>
                ) : (
                    <div>
                        <div className="form-group">
                            <label htmlFor="quantidade">Qual a quantidade de óleo vegetal que será utilizada?</label>
                            <InputField id="quantidade" placeholder="Exemplo: 15" type="number" />
                            <span>mL</span>
                        </div>

                        <div className="form-group">
                            <label htmlFor="percentual">E qual a porcentagem de diluição desejada do óleo essencial?</label>
                            <InputField id="percentual" placeholder="Exemplo: 3" type="number" />
                            <span>%</span>
                        </div>

                        <Button onClick={handleCalculate}>Calcular!</Button>
                        <InfoButton onClick={handleToggleInfo}>Sobre o cálculo</InfoButton>
                    </div>
                )}
            </FormContainer>
        </>
    );
}
