import React from 'react';
import { ButtonContainer, WhatsAppIcon } from './style';
import whatsappIcon from '../../assets/whatsapp.svg'; // Caminho para o SVG

interface WhatsAppButtonProps {
    svg?: string; // O caminho do arquivo SVG (agora opcional, já que estamos importando o ícone diretamente)
}

const WhatsAppButton: React.FC<WhatsAppButtonProps> = () => {
    return (
        <ButtonContainer
            href="https://wa.me/+5511987198919" // Substitua com o seu número de WhatsApp no formato internacional
            target="_blank"
            rel="noopener noreferrer"
        >
            <WhatsAppIcon src={whatsappIcon} alt="WhatsApp" />
        </ButtonContainer>
    );
};

export default WhatsAppButton;