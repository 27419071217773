import styled from 'styled-components';

export const ButtonContainer = styled.a`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;

  @media (max-width: 768px) {
    bottom: auto;
    top: 20px;
    right: 20px; /* Altera para o lado direito no mobile */
    left: auto;  /* Remove a definição de 'left' */
  }
`;

export const WhatsAppIcon = styled.img`
  width: 100%;
  height: 100%;
`;