import { Route, Routes } from 'react-router-dom'

import MainPage from './pages/Main'
//import BurgersPage from './pages/Main/Burgers'
//import DrinksPage from './pages/Main/Drinks'
//import IceCreamsPage from './pages/Main/IceCreams'
//import PizzasPage from './pages/Main/Pizzas'
import CellMaintenancePage from './pages/Main/CellMaintenance'
import MobileScreenPage from './pages/Main/MobileScreen'
import PhoneBattery from './pages/Main/PhoneBatteries'
import PhoneCharger from './pages/Main/PhoneChargers'
import PhoneUsbCable from './pages/Main/PhoneUSBCables'
import Privacy from './pages/Main/Privacy'

import MyCartPage from './pages/MyCart'

export function AppRoutes() {
  return (
    <Routes>
      <Route path='/' element={<MainPage />}>
        <Route path='/' element={<CellMaintenancePage />} />
        <Route path='mobile-screen' element={<MobileScreenPage />} />
        <Route path='phone-battery' element={<PhoneBattery />} />
        <Route path='phone-charger' element={<PhoneCharger />} />
        <Route path='phone-usb-cable' element={<PhoneUsbCable />} />
        <Route path='privacy' element={<Privacy />} />
      </Route>
      <Route path='cart' element={<MyCartPage />} />
    </Routes>
  )
}