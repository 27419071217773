import { Head } from '../../../components/Head';
import { SnackTitle } from '../../../components/SnackTitle';

export default function Privacy() {
    return (
        <>
            <Head title='Política de Privacidade' />
            <SnackTitle>Política de Privacidade</SnackTitle>
            <div style={{ padding: '20px', lineHeight: '1.6' }}>
                <h2>Introdução</h2>
                <p>
                    Esta Política de Privacidade descreve como coletamos, usamos e protegemos as informações pessoais que você fornece ao usar nosso aplicativo de ride sharing. Ao utilizar nossos serviços, você concorda com a coleta e uso de informações de acordo com esta política.
                </p>

                <h2>Informações que Coletamos</h2>
                <p>
                    Coletamos informações que você nos fornece diretamente, como nome, endereço de e-mail, número de telefone e informações de pagamento. Também coletamos informações automaticamente, como dados de localização e informações do dispositivo, quando você usa nosso aplicativo.
                </p>

                <h2>Como Usamos suas Informações</h2>
                <p>
                    Usamos suas informações para fornecer e melhorar nossos serviços, incluindo a facilitação de corridas, comunicação com você, processamento de pagamentos e análise do uso do aplicativo. Também podemos usar suas informações para fins de marketing, conforme permitido por lei.
                </p>

                <h2>Compartilhamento de Informações</h2>
                <p>
                    Não compartilhamos suas informações pessoais com terceiros, exceto quando necessário para fornecer nossos serviços (por exemplo, com motoristas), cumprir a lei ou proteger nossos direitos. Podemos compartilhar informações agregadas e anônimas para fins analíticos e de marketing.
                </p>

                <h2>Segurança das Informações</h2>
                <p>
                    Implementamos medidas de segurança razoáveis para proteger suas informações pessoais contra acesso não autorizado, uso ou divulgação. No entanto, nenhuma transmissão de dados pela internet é completamente segura, e não podemos garantir a segurança absoluta de suas informações.
                </p>

                <h2>Seus Direitos</h2>
                <p>
                    Você tem o direito de acessar, corrigir ou excluir suas informações pessoais a qualquer momento. Para exercer esses direitos, entre em contato conosco através do e-mail fornecido abaixo.
                </p>

                <h2>Alterações a Esta Política</h2>
                <p>
                    Podemos atualizar nossa Política de Privacidade ocasionalmente. Notificaremos você sobre quaisquer alterações, publicando a nova política neste aplicativo. Recomendamos que você revise esta política periodicamente para se manter informado sobre nossas práticas.
                </p>

                <h2>Contato</h2>
                <p>
                    Se você tiver alguma dúvida sobre esta Política de Privacidade ou sobre nossas práticas de privacidade, entre em contato conosco pelo e-mail: [seu-email@dominio.com].
                </p>
            </div>
        </>
    );
}
