import styled from 'styled-components';

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  img {
    width: 100px;
    height: auto;
  }
`;

export const Title = styled.h2`
  color: #000; /* Cor preta */
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
`;

export const FormContainer = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 2rem auto;
  text-align: center;

  .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    label {
      flex: 1;
      font-size: 1rem;
      color: #333;
    }

    input {
      flex: 0.7;
      margin-left: 1rem;
    }

    span {
      margin-left: 0.5rem;
      font-size: 1rem;
      color: #333;
    }
  }
`;

export const InputField = styled.input`
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  flex: 1;
`;

export const Button = styled.button`
  background-color: #ff00ff;
  color: white;
  font-size: 1.25rem;
  padding: 0.75rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 1rem;

  &:hover {
    background-color: #e600e6;
  }
`;

export const InfoButton = styled(Button)`
  background-color: #6e44ff;

  &:hover {
    background-color: #5933cc;
  }
`;

export const BackButton = styled.button`
  background-color: #ff7f50;
  color: white;
  font-size: 1.1rem;
  padding: 0.75rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  margin-top: 1.5rem;

  &:hover {
    background-color: #e67340;
  }
`;

export const DropShape = styled.div`
  width: 237px;
  height: 345px;
  /* background-image: url('../../../assets/drop.png'); */ /* Removido temporariamente */
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
  }
`;

export const CalculationResult = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  margin: 2rem auto;

  p {
    font-size: 1rem;
    color: #333;
    margin: 1rem 0;
    line-height: 1.5;
}

h3 {
    font-size: 1.25rem;
    color: #000;
    font-weight: bold;
    margin-bottom: 1rem;
}

ul {
    text-align: left;
    padding-left: 1.5rem;
    margin-top: 1rem;
}

li {
    font-size: 1rem;
    color: #333;
    line-height: 1.5;
}

strong {
    color: #ff00ff; /* Destaca os títulos e palavras importantes */
}
`;